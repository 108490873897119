<template>
  <v-chart class="chart" :option="option" @click="onClick" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { MapChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  VisualMapComponent
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  MapChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  VisualMapComponent
]);

import '../../echarts/map'

export default {
  name: "MapChart",
  components: {
    VChart
  },
  data() {
    return {
      option: {
        title: [
          {
            textStyle: {
              color: "#000",
              fontSize: 18
            },
            subtext: "",
            text: '中国地图',
            top: "auto",
            subtextStyle: {
              color: "#aaa",
              fontSize: 12
            },
            left: "auto"
          }
        ],
        legend: [
          {
            selectedMode: "multiple",
            top: "top",
            orient: "horizontal",
            data: [
              ""
            ],
            left: "center",
            show: true
          }
        ],
        visualMap: {
          min: 800,
          max: 50000,
          text: ['High', 'Low'],
          realtime: false,
          calculable: true,
          inRange: {
            color: ['lightskyblue', 'yellow', 'orangered']
          }
        },
        series: [
          {
            map: 'china',
            data: [],
            name: "",
            symbol: "circle",
            type: "map",
            roam: true,
            label:{
              show:true
            }
          }
        ]
      }
    };
  },
  methods:{
    onClick(evt){
      console.log('onClick',evt)
    }
  }
};
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
