<template>
  <div id="container"></div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';

window._AMapSecurityConfig = {
  securityJsCode: '166aab4f4b5d52dbc5ead115ae1f7126',
}
export default {
  name: "MapContainer",
  data() {
    return {
      //map:null,
    }
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  },
  beforeDestroy() {
    this.map.destroy();
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "0ebc613ce16f5d7acfa059d9439a6128",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.ToolBar', 'AMap.Scale', 'AMap.Geolocation', 'AMap.PlaceSearch'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("container", {  //设置地图容器id
          viewMode: "2D",    //是否为3D地图模式
          zoom: 10,  //初始化地图层级
          center: [116.397428, 39.90923]  //初始化地图中心点,
        });
        this.toolbar = new AMap.ToolBar(); // 缩放工具条实例化
        this.map.addControl(this.toolbar);
        this.scale = new AMap.Scale(); // 比例尺实例化
        this.map.addControl(this.scale);

        this.geolocation = new AMap.Geolocation({position: 'RT'});
        this.map.addControl(this.geolocation);


        // 创建一个 Marker 实例：
        this.marker = new AMap.Marker({
          position: new AMap.LngLat(116.397428, 39.90923),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: '北京'
        });
        // 将创建的点标记添加到已有的地图实例：
        this.map.add(this.marker);

        this.placeSearch = new AMap.PlaceSearch({
          pageSize: 1, // 单页显示结果条数
          pageIndex: 1, // 页码
          map: this.map, // 展现结果的地图实例
          autoFitView: false
        });

        this.placeSearch.search('深圳市宝安区福海街道展城路1号',(status, result) => {
          // 查询成功时，result即对应匹配的POI信息
          console.log(status,result)
          this.map.setZoomAndCenter(15, result.poiList.pois[0].location);
        });

      }).catch(e => {
        console.log(e);
      })
    },
  },
}
</script>

<style scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 800px;
}
</style>
